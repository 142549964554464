<template>
    <div class="productBox">
        <ul class="animate__animated animate__fadeInUp wow">
            <li v-for="(item,index) in productList" :key="index" @click="href(item.id,item.title)">
                <div class="imgLeft"><img :src="item.photo" /></div>
                <div class="listBottom">
                    <h2>{{item.title}}</h2>
                    <h3><span>{{type}}</span><span>38327</span></h3>
                </div>
            </li>
        </ul>
    </div>
</template>
<style  lang="scss" scoped>
    h2,h3{
        font-weight: normal;
    }
    .productBox{
        ul{
            display: flex;
            flex-wrap: wrap;
            li{
                width: 33%;
                text-align: center;
                margin-bottom: .3rem;
                cursor: pointer;
                .imgLeft{
                    width: 3.5rem;
                    height: 3.5rem;
                    overflow: hidden;
                    margin: 0 auto;
                    img{
                        width: 3.5rem;
                        height: 3.5rem;
                        display: block;
                        transition: all 1s;
                    }
                }
                .listBottom{
                    width: 3.5rem;
                    margin: 0 auto;
                    padding: .2rem;
                    box-sizing: border-box;
                    box-shadow: 0 0 10px 3px rgba(0,0,0,.2);
                    h2{
                        line-height: .3rem;
                    }
                    h3{
                        font-size: .13rem;
                        color: #999;
                        display: flex;
                        justify-content: space-between;
                        margin: .2rem 0 .1rem;
                    }
                }
            }
            li:hover{
                img{
                    transform:scale(1.5);
                }
            }
        }
    }
</style>
<script>
import { useRouter } from 'vue-router'
import {onMounted} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
export default {
    props:['productList','type'],
    setup(){
        onMounted(()=>{
            var wow = new WOW({
            boxClass: 'wow',    //需要执行动画元素的Class
            animateClass: 'animated',    //animation.css动画的Class
            offset: 100,    //距离可视区域多少开始执行动画
            mobile: true,    //是否在移动设备执行动画
            live: true    //异步加载的内容是否有效
            })
            wow.init()
        })
        let router = useRouter();
        function href(id,name){
            console.log(id,name)
            router.push({
            //传递参数使用params的话，只能使用name指定(在route.js里面声明name)
            path:"/productDetail",
            query:{
                id:id,
                name:name
            }
            })
        }
        return{
            href
        }
    }
}
</script>