<template>
    <Banner :imgArr="state.arr" v-if="state.arr!=''" />
    <div class="section">
        <h2 class="animate__animated animate__bounceInDown wow">{{state.curName}}</h2>
        <Product :productList="state.productList" :type="state.curName" v-if="state.productList.length > 0" />
        <div v-else class="kong"><img src="@/assets/images/kong.png" />暂无内容</div>
    </div>
</template>
<style  lang="scss" scoped>
    .section{
        width: 12rem;
        margin: 20px auto;
        h2{
            font-size: .3rem;
            text-align: center;
            padding: .3rem 0;
            font-weight: normal;
        }
    }
    .kong{
    width: 12rem;
    height: 6rem;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    font-size: .2rem;
    color: #666;
    img{
      width: 4.21rem;
      margin: .5rem auto 1rem;
    }
  }
</style>
<script>
import { useRoute } from 'vue-router'
import {onMounted,watchEffect,reactive} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
import Banner from "@/components/banner1.vue"
import Product from './components/productCard.vue'
import { getProductsList,getbannerData} from "../../api/request"
export default {
    components: {
        Banner,
        Product
    },
    setup(){
        const route = useRoute()
        const arr = [
            {img: require("@/assets/images/banner/banner1.png"),},
            {img: require("@/assets/images/banner/banner2.png"),},
            {img: require("@/assets/images/banner/banner3.png"),},
        ];
        let state = reactive({
            arr,
            curId:8,
            curName:"气象环境检测设备",
            productList: [],
        })
        onMounted(()=>{ 
            var wow = new WOW({
            boxClass: 'wow',    //需要执行动画元素的Class
            animateClass: 'animated',    //animation.css动画的Class
            offset: 100,    //距离可视区域多少开始执行动画
            mobile: true,    //是否在移动设备执行动画
            live: true    //异步加载的内容是否有效
            })
            wow.init()
        })
        watchEffect(()=>{
            state.curId = route.query.id
            let id = state.curId
            getData(id)
        })
        function getData(id){
            getbannerData({id:34}).then((res)=>{
                if(res.code == 200){
                state.arr = res.data
                }
            })
            getProductsList({id:id}).then((res) => {
                console.log(res)
                if(res.code == 200){
                    let ss =  res.data.cate
                    state.curName  = ss
                    state.productList = res.data.list
                }
            })
        }
        return{
            arr,
            state,
            getData
        }
        
    }
}
</script>